import {CheckCircle, RadioButtonUnchecked} from "@mui/icons-material";
import {ReactNode} from "react";

const defaultWidth = 110;

export const adminColumns = [
  {
    field: 'cognito_username',
    headerName: 'Username',
    headerClassName: 'header',
    minWidth: 250,
  },
  // TODO add account_created after updating person API with field
  {
    field: 'is_admin',
    headerName: 'Admin',
    headerClassName: 'header',
    minWidth: defaultWidth,
    renderCell: (params) => {
      return params.row.is_admin ? (
        <CheckCircle />
      ) : (
        <RadioButtonUnchecked />
      );
    }
  }
];