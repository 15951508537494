import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../user/Login";
import Logout from "../user/Logout";
import UpdatePassword from "../user/UpdatePassword";
import ViewHerd from "../herds/herd/ViewHerd";
import LogoutRequired from "./controllers/LogoutRequired";
import LoginRequired from "./controllers/LoginRequired";
import Herds from "../herds/Herds";
import AdminRequired from "./controllers/AdminRequired";
import UserViewPage from "../invite/UserViewPage";
import ManageOrganizations from "../admin/ManageOrganizations";
import ForgotPassword from "../user/ForgotPassword";

const Routing = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/herds" replace />} />

    {/* Session Routes */}
    <Route
      path="login"
      element={
        <LogoutRequired>
          <Login />
        </LogoutRequired>
      }
    />
    <Route
      path="logout"
      element={
        <LoginRequired>
          <Logout />
        </LoginRequired>
      }
    />
    <Route
      path="forgot-password"
      element={
        <LogoutRequired>
          <ForgotPassword />
        </LogoutRequired>
      }
    />
    <Route path="update-password" element={<UpdatePassword />} />

    {/* Feature Routes */}
    <Route
      path="herds"
      element={
        <LoginRequired>
          <Herds />
        </LoginRequired>
      }
    />
    <Route
      path="herds/:id"
      element={
        <LoginRequired>
          <ViewHerd />
        </LoginRequired>
      }
    />

    {/* Admin Routes */}
    <Route
      path="users"
      element={
        <AdminRequired>
          <UserViewPage />
        </AdminRequired>
      }
    />
    <Route
      path="organizations"
      element={
        <AdminRequired>
          <ManageOrganizations />
        </AdminRequired>
      }
    />
    <Route
      exact path="/api"
      render={() => {window.location.href="api-swagger.html"}}
    />
  </Routes>

);

export default Routing;
