import { useState, createContext, Dispatch } from "react";

interface MessageObj {
  type?: string;
  message: string;
}
interface MessageContextProps {
  messageState: [MessageObj, Dispatch<MessageObj>];
}
// @ts-ignore
const MessageContext = createContext<MessageContextProps>();


const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState<MessageObj>({ message: '' });

  const store = {
    messageState: [message, setMessage],
  } as MessageContextProps;

  return (
    <MessageContext.Provider value={store}>
      {children}
    </MessageContext.Provider>
  )
}

export { MessageContext, MessageProvider };
