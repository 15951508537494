import {Auth} from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import {CognitoUser} from "@aws-amplify/auth";

type AuthChallengeName =
  | "NEW_PASSWORD_REQUIRED"
  | "SMS_MFA"
  | "SOFTWARE_TOKEN_MFA"
  | "MFA_SETUP";

type AuthUser =  CognitoUser & {
  challengeName: AuthChallengeName
}

type UserState = {
  username: string;
};

const getIdToken = async (): Promise<string | void> => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (err) {
    // NOOP
  }
};

const getUserId = async (): Promise<number> => {
  const token = await getIdToken();
  let decodedToken;
  if (typeof token === 'string') {
    decodedToken = jwtDecode(token);
  }
  // @ts-ignore
  return decodedToken.user_id;
};

const login = async (user: string, pass: string): Promise<AuthUser> => {
  return await Auth.signIn(user, pass);
};

const getUserObject = async (): Promise<CognitoUser> => {
  return await Auth.currentAuthenticatedUser();
}

const getUser = async (): Promise<string> => {
  const userFetch = await Auth.currentAuthenticatedUser();
  return userFetch.username;
};

const forgotPassword = async (username: string): Promise<any> => Auth.forgotPassword(username);

const updatePassword = async (
  user: unknown,
  newPassword: string,
): Promise<CognitoUser> => Auth.completeNewPassword(
  user,
  newPassword
);

const updateForgottenPassword = async (
  username: string,
  code: string,
  newPassword: string,
): Promise<string> => Auth.forgotPasswordSubmit(username, code, newPassword);

const isLoggedIn = async (): Promise<boolean> => !!(await getIdToken());

const logout = async (): Promise<any> => Auth.signOut();

export default {
  login,
  getUser,
  getUserObject,
  getIdToken,
  isLoggedIn,
  logout,
  getUserId,
  forgotPassword,
  updatePassword,
  updateForgottenPassword,
};
