import {ChangeEvent} from "react";
import {
    FormControlLabel,
    Paper,
    Radio,
    Stack,
    Typography
} from "@mui/material";
interface AgreementConsentInterface {
    name: string;
    label: string;
    value: number;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
export default function AgreementConsent({ name, label, value, handleChange }: AgreementConsentInterface) {
    const borderStyle = '1px solid #cfcfcf';
    return (
        <Paper sx={{p: 2, border: borderStyle}}>
          <Stack direction='row'
                 spacing={{ xs: 1, sm: 2, md: 4 }}
                 alignItems='center'>
            <Typography variant='body1' sx={{width:'50%'}}>{label}</Typography>
            <Stack direction='row' spacing={1}>
              <FormControlLabel
                control={
                  <Radio
                    name={name}
                    checked={value === 1}
                    value={1}
                     onChange={handleChange}/>
                }
                  label='Yes'
               labelPlacement='end'/>
                 <FormControlLabel
                   control={
                            <Radio
                                name={name}
                                checked={value === 0}
                                value={0}
                                onChange={handleChange}
                            />
                        }
                        label='No'
                        labelPlacement='end'
                    />
                </Stack>
            </Stack>
        </Paper>
    );
}