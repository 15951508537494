import React from 'react';
import { Typography } from '@mui/material';

const DefaultBlockComponent = () => (
  <Typography variant='h2'>User not authorized to access this page.</Typography>
)

const BlockIf = ({
  condition,
  waitFor = true,
  pendingMsg = 'Loading...',
  blockComponent = DefaultBlockComponent,
  children,
}) => {
  const BlockComponent = blockComponent;

  if (!waitFor) {
    return <Typography variant="h3">{pendingMsg}</Typography>;
  }
  if (!condition) {
    return <BlockComponent />;
  }
  return children;
};

export default BlockIf;
