
const verifySmartyResponse = (response) => {

    const metadata = response?.metadata;
    const footnotes = response?.analysis?.footnotes;
    const components = response?.components;

    if (!metadata?.county_fips) return 'County FIPS code could not be determined';
    if (footnotes) {
        if (footnotes.includes('A#')) return `Check zipcode, suggested value: ${components?.zipcode}`;
        if (footnotes.includes('B#')) return `Check spelling of City, suggested value: ${components?.city_name}`;
        if (footnotes.includes('F#')) return `The address, exactly as submitted, could not be found in the city, state, or ZIP Code provided.`;
        if (footnotes.includes('L#')) return `Check all address components, suggested value: ${components?.primary_number} ${components?.street_predirection || ''} ${components?.street_name} ${components?.street_suffix || ''}`;
        if (footnotes.includes('M#')) return `Check spelling of street name, suggested value: ${components?.street_name}`;
    }

    return '';
};

export { verifySmartyResponse };