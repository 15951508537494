import {useQuery} from "react-query";
import orgAPI from "../apis/orgAPI";
import {ChangeEvent, useEffect, useMemo} from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel, MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IOrganizationForm from "./IOrganizationForm";

export default function OrganizationForm({ organization, handleSave, formState, setFormState, defaultFormState, setShowEdit, showEdit}: IOrganizationForm) {
    const foundOrgTypes = useQuery('fetchOrgTypes', () => orgAPI.getOrgTypes())
    const orgTypesList = useMemo(() => foundOrgTypes.isSuccess ? foundOrgTypes.data as Record<string, string | number>[] : [], [foundOrgTypes])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { name, value } } = event;
        const parseList = ['code'];
        setFormState({ ...formState, [name]: parseList.includes(name) && value !== '' ? parseInt(value) : value });
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        const { target: { name, value } } = event;
        const foundOrgType = orgTypesList.find((type) => type.abbrev === value)
        setFormState({ ...formState, [name]: value, org_type_id: foundOrgType?.id as string, org_type_name: foundOrgType?.name as string });
    }

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { name, checked } } = event;
        setFormState({ ...formState, [name]: checked });
    }

    useEffect(() => {
        if (organization) {
            setFormState({
                code: organization?.code,
                name: organization?.name,
                abbrev: organization?.abbrev,
                org_type_id: organization?.org_type_id,
                org_type_name: organization?.org_type_name,
                org_type_abbrev: organization?.org_type_abbrev,
                description: organization?.description,
                active: organization?.active,
            })
        } else {
            setFormState(defaultFormState)
        }
    }, [organization])

    return (
       <>
         {showEdit && <Stack direction="column" spacing={2} sx={{width: '100%', maxWidth: 400, mx: 'auto'}}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{width: '100%'}}>
                        <CloseIcon onClick={() => {
                            console.info('click v.02!');
                            setShowEdit(false)
                        }}/>
                    </Stack>
                    <FormControlLabel
                        control={
                            <Checkbox name="active" checked={formState.active ? true : false}
                                      onChange={handleCheckboxChange}/>
                        }
                        label="Active"
                        sx={{width: 100}}
                    />

                    <TextField
                        required
                        id="herd-code-input"
                        name="code"
                        label="Code"
                        value={formState.code}
                        onChange={handleInputChange}
                        fullWidth
                    />

                    <TextField
                        required
                        id="herd-name-input"
                        name="name"
                        label="Name"
                        value={formState.name}
                        onChange={handleInputChange}
                        fullWidth
                    />

                    <TextField
                        id="herd-abbreviation-input"
                        name="abbrev"
                        label="Abbreviation"
                        value={formState.abbrev}
                        onChange={handleInputChange}
                        fullWidth
                    />

                    <TextField
                        id="herd-description-input"
                        name="description"
                        label="Description"
                        value={formState.description}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={2}
                    />

                    <FormControl required sx={{width: 100}}>
                        <InputLabel id="herd-type-label">Type</InputLabel>
                        <Select
                            labelId="herd-type-label"
                            id="herd-type"
                            name="org_type_abbrev"
                            label="Type"
                            value={formState.org_type_abbrev}
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="">No Selection</MenuItem>
                            {orgTypesList.map((option) => (
                                <MenuItem key={option.id} value={option.abbrev}>{option.abbrev}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{width: '100%'}}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleSave}
                            disabled={!(formState.code && formState.name && formState.org_type_id)}
                        >
                            {organization?.name ? 'Update' : 'Create'}
                        </Button>
                    </Stack>
                </Stack>}
       </>
    )
}
