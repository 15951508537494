import {characteristics} from '../lib/herd/characteristics';

const extractEmpty = (dbAlias) => {
  return Object.assign({}, ...characteristics
    .find(group => group.dbAlias === dbAlias).options
    .map(item => ({[item.key]: false})));
};

const extractCharacteristics = data => {
return Object.assign({}, ...characteristics.map(group => {
    const groupName = group.dbAlias;
    if (!data[groupName])
      return {[groupName]: extractEmpty(groupName)};
    else if (typeof data[groupName] === 'string')
      return {[groupName]: JSON.parse(data[groupName])};
    else
      return {[groupName]: data[groupName]};
  }));
};

export { extractCharacteristics };