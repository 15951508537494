import React from 'react';
import RedirectIf from './RedirectIf';
import Auth from '../../auth';

export default function LoginRequired({ children }) {
  return (
    <RedirectIf
      conditionCallback={async () => !(await Auth.isLoggedIn())}
      redirectTo="/login"
      pendingMsg="Checking for Session..."
    >
      {children}
    </RedirectIf>
  );
}
