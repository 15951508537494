import Routing from './route/Routing';

import AppLayout from "./layouts/AppLayout";

export default function App() {
  return (
    <AppLayout>
      <Routing />
    </AppLayout>
  )
};
