import {createContext, useEffect, useReducer} from "react";
import personAPI, {HerdPerson} from "../apis/personAPI";
import Auth from "../auth";

const initialState = {
    username: '',
    orgId: 0,
    orgName: '',
    orgType: '',
    orgTypeAbbrev: '',
    isAdmin: false,
    userObject: null,
    attemptedUsername: '',
    attemptedPassword: ''
};

/**
 * This reducer function controls how the context receives changes to state.
 * @param state
 * @param action
 */
const reducerFn = (state, action) => {
    let newState;
    switch(action.type) {
        case 'setUserLogin':
            newState = {
                ...state,
                username: action.data.username,
                orgId: action.data.orgId,
                orgName: action.data.orgName,
                orgType: action.data.orgType,
                orgTypeAbbrev: action.data.orgTypeAbbrev,
                isAdmin: action.data.isAdmin,
                userObject: action.data.userObject || state.userObject
            };
            return newState;
        case 'unsetUserLogin':
            newState = {
                ...state,
                username: initialState.username,
                orgId: initialState.orgId,
                orgName: initialState.orgName,
                orgType: initialState.orgType,
                orgTypeAbbrev: initialState.orgTypeAbbrev,
                isAdmin: initialState.isAdmin,
                userObject: initialState.userObject
            };
            return newState;
        case 'setAttemptedLogin':
            newState = {
                ...state,
                attemptedUsername: action.data.attemptedUsername,
                attemptedPassword: action.data.attemptedPassword
            };
            return newState;
        default:
            return state;
    }
};

// @ts-ignore
const SessionContext = createContext();

const SessionProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducerFn, initialState);

    // Function to set user login.  Data fields should match the fields in the reducer and initial state.
    const setUserLogin = (username: string, cognitoObject, userInfo: HerdPerson | undefined) => {
        dispatch({
            type: 'setUserLogin',
            data: {
                username,
                orgId: userInfo?.org_id || 0,
                orgName: userInfo?.org_name || '',
                orgType: userInfo?.org_type_id || '',
                orgTypeAbbrev: userInfo?.org_type_abbrev || '',
                isAdmin: userInfo?.is_admin || '',
                userObject: cognitoObject
            }
        });
    }

    useEffect(() => {
        const getUserIfLoggedIn = async (): Promise<string | null> => {
            const loggedIn = await Auth.isLoggedIn();
            if (loggedIn) {
                return await Auth.getUser()
            }
            return null;
        };
        getUserIfLoggedIn().then((username) => {
            if (username) {
                personAPI.getByName(username).then(userInfo => {
                    setUserLogin(username, null, userInfo.data);
                });
            }
        });
    }, []);

    /**
     * The functions inside providerValue get exposed to any component using the session.
     * The useSession hook uses these functions in its login/logout functions.
     */
    const providerValue = {
        ...state,
        setUserLogin,
        unsetUserLogin: () => dispatch({type: 'unsetUserLogin'}),
        setAttemptedLogin: (attemptedUsername, attemptedPassword) => dispatch({type: 'setAttemptedLogin', data: {
            attemptedUsername,
            attemptedPassword
        }})
    }

    return (
        <SessionContext.Provider value={providerValue}>
            {children}
        </SessionContext.Provider>
    );
};

export {SessionContext, SessionProvider};