import Auth from "../auth";
import { useNavigate } from "react-router-dom";
import {useEffect} from "react";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.logout().then(success => navigate('/login'));
  }, []);

  return (
    <></>
  );
};

export default Logout;