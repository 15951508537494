import { useState, createContext, Dispatch } from "react";


interface MessageContextProps {
  searchState: [string, Dispatch<string>];
}
// @ts-ignore
const SearchContext = createContext<MessageContextProps>();


const SearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState('');

  const store = {
    searchState: [searchValue, setSearchValue],
  } as MessageContextProps;

  return (
    <SearchContext.Provider value={store}>
      {children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider };
