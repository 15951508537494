import React from 'react';
import {Box} from "@mui/material";

const PdfWrapper = ({id, show = false, children}) => {

  return (
    <Box
      id='pdf-resizeable'
      >
      <Box
        id={id}
        sx={{
          display: 'flex',
          width: show ? 'default' : '0mm', // visibility: hidden and display: none cause issues with pdf generation
          height: show ? 'default' : '0mm',
          overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // border: 'dotted', // uncomment for display debugging
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PdfWrapper;