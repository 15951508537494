import { ReactNode } from 'react';
import { Tooltip, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';

interface AccordionWrapperProps {
  openAccordion: string | boolean;
  handleChange: (panel: string, isExpanded: boolean) => void;
  heading: string;
  children: ReactNode;
  sx?: Record<string, string | number | Record<string, string | number>>;
}

export default function AccordionWrapper({openAccordion, handleChange, heading, children, sx}: AccordionWrapperProps) {
  return (
    <Accordion expanded={openAccordion === heading || openAccordion === true} onChange={(event, isExpanded) => {handleChange(heading, isExpanded)}}  sx={{ ...sx, width: '100%', height: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
        <Tooltip title={heading}>
          <Typography variant='h6'>{heading}</Typography>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}