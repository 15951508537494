import axios from 'axios';
// import publicKeys from '../../util/publicKeys';
import { fetchConfig } from '../../util';

interface GeoCoordinates {
  latitude?: number;
  longitude?: number;
}

interface VerificationInfo {
  verification_status?: string;
  address_precision?: string;
}

interface SmartyInternationalResponse {
  analysis: VerificationInfo;
  metadata: GeoCoordinates;
}

interface I18nAddress {
  country: string;
  administrativeArea: string | null;
  locality: string;
  address1: string;
  address2?: string;
  postalCode: string;
}

const verifyUSAddress = async (address: I18nAddress) => {
  const smartyConfig = fetchConfig("SmartyApi");
  const response = await axios.get(
    `${smartyConfig.baseUrl}/street-address?state=${address.administrativeArea}&city=${address.locality}&street=${address.address1}&zipcode=${address.postalCode}&key=${smartyConfig.apiKey}&geocode=true`,
  );

  return response.data[0];  
}

export default { verifyUSAddress };
