import { ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';

export interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  other?: any;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  const theme = useTheme();
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 4, px: { xs: 1, md: 0 } }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

export default TabPanel;
