import Auth from "../../auth";
import { SessionContext } from "../../context/sessionContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import personAPI from "../../apis/personAPI";

/**
 * Session hook to control login/logout, view user info, and manage password.
 * @returns {{userInfo: {orgName, orgType, orgTypeAbbrev, isAdmin, orgId, username}, logout: logout, updatePassword: updatePassword, isLoggedIn: (function(): Promise<boolean>), login: login}}
 */
const useSession = () => {
  const {
    userObject,
    username,
    orgName,
    orgId,
    isAdmin,
    orgType,
    orgTypeAbbrev,
    setAttemptedLogin,
    setUserLogin,
    unsetUserLogin
  } = useContext(SessionContext);

  const navigate = useNavigate();

  const isLoggedIn = () => Auth.isLoggedIn();

  const determineUserInfo = async () => {
    const username = await Auth.getUser();
    return (await personAPI.getByName(username)).data;
  };

  const login = (username, password, onSuccess, onError) => {
    const attemptPayload = {
      attemptedUsername: username,
      attemptedPassword: password
    };
    setAttemptedLogin(attemptPayload);
    Auth.login(username, password)
      .then(response => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserLogin(response.getUsername(), response);
          navigate('../update-password', { replace: true });
        }
        else {
          determineUserInfo().then(userInfo => {
            setUserLogin(response.getUsername(), response, userInfo);
            onSuccess();
          }).catch(apiError => {
            onError(apiError);
          });
        }
      }).catch((err) => {
        onError(err);
      });
  };

  const logout = () => {
    Auth.logout().then(success => {
      unsetUserLogin();
      navigate('/login');
    });
  }

  const updatePassword = (password, onSuccess, onError) => {
    Auth.updatePassword(userObject, password).then(user => {
      determineUserInfo().then(userInfo => {
        setUserLogin(user.getUsername(), user, userInfo);
      });
      onSuccess(user);
    }).catch(onError);
  }

  const userInfo = {
    username,
    orgName,
    orgId,
    orgType,
    orgTypeAbbrev,
    isAdmin
  };

  return {
    userInfo,
    isLoggedIn,
    login,
    logout,
    updatePassword
  }
};

export default useSession;