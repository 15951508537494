import { UserDateTimeProps } from "../../herdportalTypes";
import { formatDate } from '../../util/dateUtil';
import {Box} from "@mui/material";

export default function HerdInformationForm({ username, org, dateTime }: UserDateTimeProps) {
    if (!org) org = 'system';
    return (
        <>
            {username && dateTime ?
              <Box justifyContent={"flex-start"} style={{color: 'gray', fontFamily: 'sans-serif', fontSize: '12px'}}>Updated by {username}: {org} at {formatDate(dateTime)}</Box> :
              <Box justifyContent={"flex-start"} style={{color: 'gray', fontFamily: 'sans-serif', fontSize: '12px'}}>Record not updated</Box>
            }
        </>
    )
}