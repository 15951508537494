import axios from "axios";
import { fetchConfig } from '../util';
import Auth from '../auth/index'
import {IOrganization} from "../admin/IOrganization";

const HOST_URL = fetchConfig('hostUrl');

interface orgAPIProps {
  getOrgTypes: () => Promise<unknown>;
  getAll: () => Promise<unknown>;
  getOne: (orgId: string) => Promise<unknown>;
  create: (updates: IOrganization) => Promise<unknown>;
  update: (orgId: string, updates: IOrganization) => Promise<unknown>;
}

const orgAPI: orgAPIProps = {
  getOrgTypes:async () => (await axios.get(`${HOST_URL}org-type`, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  getAll: async () => (await axios.get(`${HOST_URL}organization`, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  getOne: async (orgId) => (await axios.get(`${HOST_URL}organization/${orgId}`, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  create: async (updates) => (await axios.post(`${HOST_URL}organization`, updates, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  } )).data,

  update: async (orgId, updates) => (await axios.put(`${HOST_URL}organization/${orgId}`, updates, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,
};

export default orgAPI;
