import { useEffect, useContext, ReactNode, useState } from "react";
import { Box, Stack, useTheme, useMediaQuery } from "@mui/material";

import { MessageContext } from "../context/messageContext";
import NavBar from './NavBar';
import MessagePopup from "../components/MessagePopup";

interface AppLayoutProps {
  children?: ReactNode
}

export default function AppLayout({ children }: AppLayoutProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { messageState } = useContext(MessageContext);
  const [message, setMessage] = messageState;
  const [messageOpen, setMessageOpen] = useState(false);

  const onMessageClose = () => {
    setMessageOpen(false);
    setMessage({ type: message.type, message: '' });
  };

  useEffect(() => {
    if (message.type && message.message) {
      setMessageOpen(true);
    }
  }, [message]);

  return (
    <Stack direction="column" spacing={0} sx={{ mt: {xs: 2, sm: 0} }}>
      {!isMobile && <NavBar />}
      <Box sx={{ width: {xs: '96%', md: '90%'}, minHeight: 5, maxWidth: 'lg', mx: 'auto', py: 2, height: '100%' }} >
        {children}
      </Box>
      {isMobile && <NavBar sx={{ position: 'sticky', bottom: 0 }} />}
      <MessagePopup severity={message.type} message={message.message} open={messageOpen} onClose={onMessageClose} />
    </Stack>
  );
}
