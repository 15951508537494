import { useState, useEffect, useContext, ChangeEvent } from 'react';
import { AxiosError } from 'axios';
import { Box, Stack, Switch, useTheme, useMediaQuery, Button, FormControl, FormControlLabel, FormGroup, CircularProgress, Checkbox } from '@mui/material';
import {useMutation, useQueryClient} from 'react-query';

import {HerdCharacteristics, HerdPanelProps, HerdViewObject} from '../../herdportalTypes';
import { MessageContext } from '../../context/messageContext';
import herdAPI from '../../apis/herdAPI';
import { characteristics } from '../../lib/herd/characteristics';
import AccordionWrapper from '../../components/AccordionWrapper';
import UsernameDateTime from "./UserDateTimeBox";
import {extractCharacteristics} from "../util";

export default function CharacteristicsPanel({ id, herd, herdRefetch }: HerdPanelProps) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const useDesktopLayout = useMediaQuery(theme.breakpoints.up('md'));
  const { messageState } = useContext(MessageContext);

  const [, setMessage] = messageState;
  const [keepAccordionsOpen, setKeepAccordionsOpen] = useState(true);
  const [openAccordion, setOpenAccordion] = useState<string | boolean>(true);
  const [chars, setChars] = useState<HerdCharacteristics>(extractCharacteristics(herd));

  const handleToggleKeepAccordionsOpen = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { checked } } = event;
    setKeepAccordionsOpen(checked)
  };

  const handleAccordionChange = (panel: string, isExpanded: boolean) => {
    if (!useDesktopLayout && !keepAccordionsOpen) setOpenAccordion(isExpanded ? panel : false)
  };

  const handleCheckboxChange = (event, dbAlias) => {
    const groupDefn = chars[dbAlias];

    const boxName = event.target.name;
    const isBoxChecked = event.target.checked;

    if (boxName.includes('none') && isBoxChecked)
      for (const item of Object.keys(groupDefn))
        groupDefn[item] = false;
    else if (Object.keys(groupDefn).some(item => item.includes('none')) && !boxName.includes('none'))
      for (const item of Object.keys(groupDefn))
        if (item.includes('none'))
          groupDefn[item] = false;

    groupDefn[boxName] = isBoxChecked;

    setChars({
      ...chars,
      [dbAlias]: groupDefn
    });
  };

  const handleSubmitCharacteristics = useMutation(
    () => herdAPI.update(herd.code as string, { info: {}, characteristics: chars}),
    {
      onMutate: async () => {
        // The refetch can take several seconds.  To expedite PDF support, we need to perform
        // an optimistic update to the herd query data.
        // TODO organize this logic

        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: ["getOneHerd", `${herd.code}`] });

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(["getOneHerd", `${herd.code}`]);

        // Optimistically update to the new value
        queryClient.setQueryData(["getOneHerd", `${herd.code}`], (old) => ({...old as HerdViewObject, ...chars}));
        // TODO Will this work the same with ...chars instead of ...radios?

        // Return a context object with the snapshotted value
        return { previousData }
      },
      onSuccess: () => {
        herdRefetch();
        setMessage({type: 'success', message: 'Characteristics updated.'});
      },
      onError: (error: AxiosError, radios, context) => {
        console.error(error.response?.data.message);
        queryClient.setQueryData(["getOneHerd", `${herd.code}`], context?.previousData);
        setMessage({type: 'error', message: 'Could not update characteristics.'})
      }
    }
  );

  useEffect(() => {
    setChars(extractCharacteristics(herd));
  }, [herd]);

  useEffect(() => {
    setOpenAccordion(keepAccordionsOpen)
  }, [keepAccordionsOpen])

  return (
    <Box id={id}>
      {!useDesktopLayout && <Stack direction="row" justifyContent="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={keepAccordionsOpen}
              disabled={useDesktopLayout}
              onChange={handleToggleKeepAccordionsOpen}
              sx={{ fontSize: theme.typography.caption.fontSize, fontWeight: 300 }}
            />
          }
          label="Expand All"
          labelPlacement='start'
          sx={{ '& .MuiTypography-root': { typography: theme.typography.body2 } }}
        />
      </Stack>}
      
      <Stack direction="row" spacing={0} flexWrap="wrap">
        {characteristics.map((group) => (
          <Box key={group.heading} sx={{ m: {xs: 0, md: '0.5%'}, width: { xs: '100%', md: '32%' } }}>
            <AccordionWrapper
              openAccordion={openAccordion}
              handleChange={handleAccordionChange}
              heading={group.heading}
              sx={{ m: 0 }}
            >
              <CharacteristicsChecklist
                checklist={group.options}
                onChange={event => handleCheckboxChange(event, group.dbAlias)}
                values={chars[group.dbAlias]}
              />
            </AccordionWrapper>
          </Box>
        ))}
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 4 }}>
        <UsernameDateTime username={herd.characteristics_updated_by} org={herd.characteristics_updated_by_organization} dateTime={herd.characteristics_updated_datetime}/>
        {!handleSubmitCharacteristics.isLoading ? <Button variant='contained' color='primary' onClick={() => handleSubmitCharacteristics.mutate()}>
          Save
        </Button> : <CircularProgress />}
      </Stack>
    </Box>
  )
}

interface CharacteristicsChecklistProps {
  checklist: {key: string, label: string}[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  values: object
}

const CharacteristicsChecklist = ({checklist, onChange, values}: CharacteristicsChecklistProps) => {
  return (
    <FormControl>
      <FormGroup>
        {
          checklist.map(item => (
            <FormControlLabel
              control={
                <Checkbox checked={values[item.key]} onChange={onChange} name={item.key}/>
              }
              label={item.label}
              key={item.key}
            />
          ))
        }
      </FormGroup>
    </FormControl>
  );
};