import axios, { AxiosResponse } from "axios";
import { fetchConfig } from "../util";

const HOST_URL = fetchConfig("hostUrl");

const addAll = async (
  emailList: string[],
  orgId: number,
  isAdmin: boolean = false
): Promise<AxiosResponse<void>> =>
  axios.post(
    `${HOST_URL}user/create/org/${orgId}`,
    emailList.map((email) => ({
      email,
      isAdmin,
    }))
  );

const deactivate = async (userId) =>
  axios.put(`${HOST_URL}user/deactivate/${userId}`);

const activate = async (userId) =>
  axios.put(`${HOST_URL}user/activate/${userId}`);

const resetPassword = async (userId) =>
  axios.put(`${HOST_URL}user/reset-password/${userId}`);

export default { addAll, deactivate, activate, resetPassword };
