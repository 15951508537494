import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { Typography } from '@mui/material';

function RedirectIf({ conditionCallback, redirectTo, pendingMsg = 'Loading...', children }) {
  const [condition, setCondition] = useState();

  useEffect(() => {
    conditionCallback().then((bool) => setCondition(bool));
  }, [setCondition]);

  if (condition === true) {
    return <Navigate to={redirectTo} />;
  }

  if (condition === false) {
    return children;
  }

  return <Typography variant="h3">{pendingMsg}</Typography>;
}

export default RedirectIf;
