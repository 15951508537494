import axios, {AxiosResponse} from "axios";
import {fetchConfig} from '../util';

export type HerdPerson = {
  user_id: number;
  cognito_username: string;
  is_admin: boolean;
  org_id: number;
  org_name: string;
  org_type_id: number;
  org_type_name: string;
  org_type_abbrev: string;
}

const HOST_URL = fetchConfig('hostUrl');

const get = async (): Promise<AxiosResponse<HerdPerson[]>> =>
  axios.get(`${HOST_URL}person`);

const getByName = async (name?: string): Promise<AxiosResponse<HerdPerson>> =>
  axios.get(`${HOST_URL}person?name=${name}`);

const getById = async (id: number): Promise<AxiosResponse<HerdPerson>> =>
  axios.get(`${HOST_URL}person/${id}`);

export default {get, getByName, getById};