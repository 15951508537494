const defaultWidth = 110;

export const columns = [
  {
    field: 'code',
    headerName: 'Code',
    headerClassName: 'header',
    minWidth: defaultWidth,
  },
  {
    field: 'owner_name',
    headerName: 'Owner Name',
    headerClassName: 'header',
    minWidth: 300
  },
  {
    field: 'street_address',
    headerName: 'Street Address',
    headerClassName: 'header',
    minWidth: 300
  },
  {
    field: 'city',
    headerName: 'City',
    headerClassName: 'header',
    minWidth: 200
  },
  {
    field: 'state_code',
    headerName: 'State',
    headerClassName: 'header',
    minWidth: defaultWidth
  },
  {
    field: 'zip_code',
    headerName: 'Zip Code',
    headerClassName: 'header',
    minWidth: defaultWidth
  }
];