import {useContext} from "react";
import {MessageContext} from "../../context/messageContext";

const MESSAGE_MAP = {
  "Username/client id combination not found.": "Username is not found.",
  "User password cannot be reset in the current state.": "Username is not found."
};

const useAlert = () => {
  const { messageState } = useContext(MessageContext);
  const [, setMessage] = messageState;

  const mapMessage = (message) => {
    const result = MESSAGE_MAP[message];
    return result ? result: message;
  };

  const processMessage = (type, message) => {
    setMessage({type, message: mapMessage(message)});
  };

  const success = (message) => {
    processMessage('success', message);
  };

  const info = (message) => {
    processMessage('info', message);
  };

  const warn = (message) => {
    processMessage('warn', message);
  };

  const error = (message) => {
    processMessage('error', message);
  };

  return {
    success,
    info,
    warn,
    error
  };
};

export default useAlert;