import {useMediaQuery, useTheme} from "@mui/material";

const useResponsive = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    isDesktop,
    isPhone,
  };
};

export {useResponsive};