import BlockIf from "./BlockIf";
import useSession from "../../hooks/user/useSession";

const AdminRequired = ({ children }) => {
  const { userInfo } = useSession();

  const canAccess = userInfo.isAdmin || userInfo.orgTypeAbbrev === 'SU';

  return (
    <BlockIf condition={canAccess}
             pendingMsg='Checking Credentials...'
    >
      {children}
    </BlockIf>
  );
};

export default AdminRequired;