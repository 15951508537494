const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

const subtractHours = (offset, date) => {
  if(!date) return;
  let numOfHours = 0;

  if (offset && offset > 60) {
    numOfHours = Math.floor(offset/60);
  }

  date.setHours(date.getHours() - numOfHours);
  return date;
}

const formatDate = (dateString) => {
  const inputDate = new Date(dateString);
  const offset = inputDate.getTimezoneOffset();
  const date = subtractHours(offset, inputDate);
  return (
    padTo2Digits(date.getMonth() + 1) + '/' +
    padTo2Digits(date.getDate()) + '/' +
    date.getFullYear().toString().substr(-2) +
    ' ' +
    date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  );
}

// 👇️ Subtract 1 hour from the current date
const result = subtractHours(1);

// 👇️ Subtract 2 hours from another date
const date = new Date('2022-04-27T08:30:10.820');

// 👇️ Wed Apr 27 2022 06:30:10
// console.log(subtractHours(2, date));

export { formatDate };