import { Snackbar, Alert } from '@mui/material';

export default function MessagePopup({
  severity = 'error',
  message,
  duration = 6000,
  open,
  onClose,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' }
}) {
  const handleClose = () => onClose();

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={duration}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
