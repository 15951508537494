import { ChangeEvent } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

interface AgreementLevelInterface {
  name: string;
  label: string;
  value: number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  readOnly: boolean;
}
export default function AgreementLevel({
  name,
  label,
  value,
  handleChange = () => {},
  readOnly = false,
}: AgreementLevelInterface) {
  const borderStyle = "1px solid #cfcfcf";
  return (
    <Paper sx={{ p: 2, border: borderStyle }}>
      <Stack
        direction="row"
        spacing={{ xs: 2, sm: 3, md: 4 }}
        alignItems="center"
      >
        <Typography variant="body1" sx={{ marginLeft: "50px", width: "50%" }}>
          {label}
        </Typography>
        <Stack direction="row" spacing={1}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Code</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name={name}
              value={value}
              onChange={readOnly ? () => {} : handleChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="1"
                disabled={readOnly}
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="2"
                disabled={readOnly}
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3"
                disabled={readOnly}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  );
}
