import {MouseEvent, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

import NDHIAIcon from '../customIcons/NDHIAIcon';
import useSession from "../hooks/user/useSession";

const superUserPages: string[] = ['Invite Users', 'Organization Portal', 'Herd Portal'];

interface NavBarProps {
  sx?: Record<string, string | number>;
}
const NavBar = ({ sx }: NavBarProps) => {
  const {palette, breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const session = useSession();
  const {userInfo, logout} = session;

  const settings = [{
    name: 'Logout',
    action: logout
  }];

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      sx={{
        position: 'static',
        ...sx,
        backgroundColor: palette.background.default,
        color: palette.grey[700],
        m: 0,
        py: 1
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Link to="/herds" style={{textDecoration: 'none', color: 'inherit'}}>
            <Tooltip title="Go Home">
              <Box
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0}}
              >
                <NDHIAIcon />
                <Typography
                  variant="h5"
                  color={palette.grey[700]}
                  noWrap
                  component="div"
                  sx={{m: 0}}
                >
                  Herd Portal
                </Typography>
              </Box>
            </Tooltip>
          </Link>

          {userInfo.username && <Stack direction='row' spacing={2} flexWrap="wrap-reverse">
            {/* FOR 'super users' and admins ONLY */}
            <Stack id="superUserControls" direction='row' spacing={2} marginX="auto">
              {userInfo.isAdmin &&
              <Link to="/users">
                <Tooltip title="Invite Users">
                  <GroupAddOutlinedIcon
                    sx={{height: '100%', color: palette.grey[700], ':hover': {color: palette.primary.main}}}/>
                </Tooltip>
              </Link>}
              {userInfo.orgTypeAbbrev === "SU" &&
              <Link to="/organizations">
                <Tooltip title="Manage Organizations">
                  <CorporateFareOutlinedIcon
                    sx={{height: '100%', color: palette.grey[700], ':hover': {color: palette.primary.main}}}/>
                </Tooltip>
              </Link>}
              <Link to="/herds">
                <Tooltip title="Manage Herds">
                  <AppRegistrationOutlinedIcon
                    sx={{height: '100%', color: palette.grey[700], ':hover': {color: palette.primary.main}}}/>
                </Tooltip>
              </Link>
            </Stack>
            <Stack direction='row' spacing={2} alignItems="center">
              {!isMobile && <Typography variant='h6' fontSize="1.1rem">
                {userInfo.orgName}
                {/* {activeUser?.herd?.data_access_level} */}
              </Typography>}
              <Tooltip title="User menu">
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0, height: '100%'}}>
                  <Avatar alt={userInfo.username as unknown as string} sx={{backgroundColor: '#01674F', height: 60, width: 60}}/>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.name} onClick={setting.action}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Stack>
          </Stack>}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
