import { SyntheticEvent, useContext, useState } from "react";
import {Box, Button, CircularProgress, Stack, Tab, Tabs, useMediaQuery, useTheme} from "@mui/material";
import { useParams } from "react-router-dom";
import {useQuery, UseQueryResult} from "react-query";

import TabPanel from "./TabPanel";
import AgreementsPanel from "./AgreementsPanel";
import InformationPanel from "./InformationPanel";
import CharacteristicsPanel from "./CharacteristicsPanel";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import herdAPI from "../../apis/herdAPI";
import { MessageContext } from "../../context/messageContext";
import { HerdViewObject } from "../../herdportalTypes";
import {exportToPdfImage} from "../../util/pdf";
import PdfWrapper from "../../components/PdfWrapper";
import {useResponsive} from "../../hooks/style";
import HistoryPanel from "./HistoryPanel";

const ViewHerd = () => {
  const {isDesktop} = useResponsive();
  const tabList = ["General Information", "Characteristics", "Agreements", "History"];

  const { id: herdCode } = useParams();
  const { palette, typography } = useTheme();
  const {
    messageState: [, setMessage],
  } = useContext(MessageContext);

  const [selectedTab, setSelectedTab] = useState(0);

  /**
   * QUERY FOR SINGLE HERD
   */
  const foundHerd: UseQueryResult<HerdViewObject, Error> = useQuery(
    ["getOneHerd", herdCode],
    () => herdAPI.getOne(herdCode ? herdCode : ""),
    {
      onError: (error: Error) => {
        console.error("getOneHerd useQuery Error:", error);
        setMessage({ type: "error", message: error.message });
      },
    }
  );

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleQueryRefetch = () => {
    foundHerd.refetch();
  };

  return (
    <>
      <BreadcrumbNav tab={tabList[selectedTab]} />
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{
          maxWidth: { xs: "sm", sm: "100%" },
          backgroundColor: palette.background.paper,
        }}
      >
        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          aria-label="Herd section tabs."
        >
          {tabList.map((tab) => (
            <Tab
              key={tab}
              label={tab}
              sx={{
                fontSize: { xs: 12, md: typography.body2.fontSize },
                px: { xs: 0.5, sm: 1, md: 2 },
              }}
            />
          ))}
        </Tabs>
        <Button disabled={!foundHerd.isSuccess} onClick={() => exportToPdfImage(['information', 'characteristics', 'agreements'], isDesktop)}>
          {foundHerd.isSuccess ? 'Export to PDF' : <CircularProgress size={15} sx={{ ml: '8px' }} />}
        </Button>
      </Stack>
      {foundHerd.isFetched && !foundHerd.isLoading ? (
        <>
          <TabPanel value={selectedTab} index={0}>
            <InformationPanel
              id='information-display'
              herd={foundHerd.data as HerdViewObject}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <CharacteristicsPanel
              id='characteristics-display'
              herd={foundHerd.data as HerdViewObject}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <AgreementsPanel
              id='agreements-display'
              herd={foundHerd.data as HerdViewObject}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <HistoryPanel
              id='history-display'
              herd={foundHerd.data as HerdViewObject}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="info" />
        </Box>
      )}
      {foundHerd.isSuccess &&
        <PdfWrapper id='pdf-export'>
          <InformationPanel
            id='information'
            herd={foundHerd.data}
            herdRefetch={handleQueryRefetch}
          />
          <CharacteristicsPanel
            id='characteristics'
            herd={foundHerd.data}
            herdRefetch={handleQueryRefetch}
          />
          <AgreementsPanel
            id='agreements'
            herd={foundHerd.data}
            herdRefetch={handleQueryRefetch}
          />
        </PdfWrapper>
      }
    </>
  );
};

export default ViewHerd;
