export interface CharacteristicObjType {
  heading: string;
  options: {key: string; label: string;}[];
  dbAlias: string;
}

export const characteristics: CharacteristicObjType[] = [
  {
    heading: 'Housing Systems',
    options: [
      {key: 'tie_stall', label: 'Tie Stall'},
      {key: 'pasture', label: 'Pasture'},
      {key: 'free_stall', label: 'Free Stall'},
      {key: 'dry_lot', label: 'Dry Lot'},
      {key: 'compost_barn', label: 'Compost Barn'},
      {key: 'hybrid', label: 'Hybrid'}
    ],
    dbAlias: 'housing_systems'
  },
  {
    heading: 'Milking Systems',
    options: [
      {key: 'tie_stall', label: 'Tie Stall'},
      {key: 'flat_barn', label: 'Flat Barn'},
      {key: 'parallel_or_herringbone', label: 'Parallel/Herringbone'},
      {key: 'rotary', label: 'Rotary'},
      {key: 'robotic_ams', label: 'Robotic (AMS)'}
    ],
    dbAlias: 'milking_systems'
  },
  {
    heading: 'Feeding Systems',
    options: [
      {key: 'tmr', label: 'TMR'},
      {key: 'pmr', label: 'PMR'},
      {key: 'forage', label: 'Forage'},
      {key: 'grazing', label: 'Grazing'},
      {key: 'topdress', label: 'Topdress'}
    ],
    dbAlias: 'feeding_systems'
  },
  {
    heading: 'Transition Programs',
    options: [
      {key: 'none', label: 'None'},
      {key: 'prefresh', label: 'Prefresh'},
      {key: 'postfresh', label: 'Postfresh'}
    ],
    dbAlias: 'transition_programs'
  },
  {
    heading: 'Milk Yield Sources',
    options: [
      {key: 'dhi_meters', label: 'DHI Meters'},
      {key: 'on_farm_meters', label: 'On Farm Meters'},
      {key: 'robotic_ams', label: 'Robotic (AMS)'},
      {key: 'inline_sensor', label: 'Inline Sensor'},
      {key: 'bucket', label: 'Bucket'}
    ],
    dbAlias: 'milk_yield_sources'
  },
  {
    heading: 'Milk Marketing',
    options: [
      {key: 'conventional', label: 'Conventional'},
      {key: 'organic', label: 'Organic'},
      {key: 'home_manufacturing_and_direct_sales', label: 'Home Manufacturing + Direct Sales'}
    ],
    dbAlias: 'milk_marketing'
  },
  {
    heading: 'Repro/Breeding Programs',
    options: [
      {key: 'visual_observation', label: 'Visual Observation'},
      {key: 'sensor_based', label: 'Sensor Based'},
      {key: 'timed_or_synced', label: 'Timed/Synced'},
      {key: 'natural_service', label: 'Natural Service'}
    ],
    dbAlias: 'repro_breeding_programs'
  },
  {
    heading: 'Pregnancy Confirmation',
    options: [
      {key: 'palpation', label: 'Palpation'},
      {key: 'ultrasound', label: 'Ultrasound'},
      {key: 'milk_elisa', label: 'Milk ELISA'},
      {key: 'blood_elisa', label: 'Blood ELISA'},
      {key: 'inline_sensor', label: 'Inline Sensor'},
      {key: 'none_or_non_return', label: 'None/Non-Return'}
    ],
    dbAlias: 'pregnancy_confirmation'
  },
  {
    heading: 'Heat Abatement Systems',
    options: [
      {key: 'none', label: 'None'},
      {key: 'fans', label: 'Fans'},
      {key: 'misters', label: 'Misters'},
      {key: 'cooling_ponds', label: 'Cooling Ponds'}
    ],
    dbAlias: 'heat_abatement_systems'
  }
];