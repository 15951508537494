import {Button, Paper, Stack, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import useSession from "../hooks/user/useSession";
import {ChangeEvent, useEffect, useState} from "react";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const { updatePassword } = useSession();

  const passwordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.[{}();.#@$^&!%*?&'"\]\[])[A-Za-z\d{}();.#@$^&!%*?&'"\]\[]{10,}$/
);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [inputsValid, setInputsValid] = useState(false);

  const validateInputs = () => {
    const validPassword = passwordRegex.test(password);
    const validConfirmPassword = password == confirmPassword;
    const inputsValid = validPassword && validConfirmPassword;
    setInputsValid(inputsValid);
  }

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = event;
    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
        console.error(`Cannot handle change event.`);
        break;
    }
  };

  const handlePasswordUpdate = () => updatePassword(
    password,
    () => navigate('/'),
    (err: any) => console.error('Could not complete password update', err)
  );

  useEffect(validateInputs, [password, confirmPassword]);

  return (
    <Paper sx={{ px: 0, py: 6, width: '100%', maxWidth: 'sm', mx: 'auto' }}>
      <Stack direction='column' spacing={4} alignItems='center' sx={{ maxWidth: 300, mx: 'auto' }}>
        <Typography variant='h4'>Update Password</Typography>

        <TextField
          variant='outlined'
          error={!passwordRegex.test(password) && !!password}
          type='password'
          label='Password'
          helperText='Minimum of 10 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character.'
          name='password'
          value={password}
          onChange={handleTextChange}
          sx={{ width: '100%' }}
        />
        <TextField
          variant='outlined'
          error={password != confirmPassword}
          type='password'
          label='Confirm Password'
          name='confirmPassword'
          value={confirmPassword}
          onChange={handleTextChange}
          sx={{ width: '100%' }}
        />
        <Stack direction="row" justifyContent="flex-end" width='100%'>
          <Button variant='contained' color='primary' disabled={!inputsValid} onClick={handlePasswordUpdate}>Update</Button>
        </Stack>
      </Stack>
    </Paper>
  )
};

export default UpdatePassword;